//
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { fetchAppData } from 'reducers/modules/app'
import { connect } from 'react-redux'

export class AppDataLoader extends Component {
    static defaultProps = {
        fetchAppData: () => {},
        history: { listen: () => {} },
    }

    constructor(props) {
        super(props)

        this.state = {
            pathname: location.pathname,
        }

        this.props.history.listen(this.onRouteChanged)
    }

    onRouteChanged = (location, action) => {
        const { pathname } = location
        const oldPathname = this.state.pathname

        this.setState({
            pathname,
        })

        if (pathname.includes('/new/')) {
            return
        }

        const parts = pathname.split('/')
        // prevent fetching of react data when switching between entity tabs
        // it should fetch it when id changes, though
        if (
            parts.length === 5 &&
            !['marketplace', 'ratecalc'].includes(parts[2])
        ) {
            const oldParts = oldPathname ? oldPathname.split('/') : []
            if (oldParts.length === 5 && oldParts[3] === parts[3]) {
                // id equals?
                return
            }
        } else if (parts.length === 6 && parts[2] === 'criteria') {
            const oldParts = oldPathname ? oldPathname.split('/') : []
            if (oldParts.length === 6 && oldParts[4] === parts[4]) {
                // id equals?
                return
            }
        }

        this.props.fetchAppData(pathname)
    }

    render() {
        return this.props.children
    }
}

export default withRouter(
    connect((state) => ({}), {
        fetchAppData,
    })(AppDataLoader)
)
