//
import * as React from 'react'
import { Component } from 'react'
import { setBreadcrumbs } from '../reducers/modules/dashboard'
import store from 'reducers/store'

/**
 * @param breadcrumbs
 * @param options
 *   options.setOnMount - update breadcrumbs on component mount (set to false to handle it in your own component)
 */
export default function breadCrumbs(breadcrumbs, options) {
    return function (WrappedComponent) {
        return class extends Component {
            componentDidMount() {
                if (options && options.setOnMount === false) {
                    return
                }

                store.dispatch(setBreadcrumbs(breadcrumbs))
            }

            pushBreadcrumb = (crumb) => {
                let result = Array.from(breadcrumbs)

                result.push(crumb)

                store.dispatch(setBreadcrumbs(result))
            }

            render() {
                return (
                    <WrappedComponent
                        pushBreadcrumb={this.pushBreadcrumb}
                        {...this.props}
                    />
                )
            }
        }
    }
}
