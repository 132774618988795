import React from 'react'
import styled from 'styled-components'
import { color, layout, typography, border, space } from 'styled-system'

const Circle = styled.div`
    ${color}
    ${layout}
	${border}
	${space}
	border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

const Icon = styled.i`
    ${color}
    ${typography}
`

const Checkmark = styled.div`
    ${color};
    ${layout};
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
Checkmark.defaultProps = {
    backgroundColor: 'green1',
    color: 'white',
}

const CheckmarkIcon = styled.i`
    ${typography}
`

/**
 * A circlular icon component w/o the background
 */
export const CircleIcon = ({
    color,
    backgroundColor,
    width,
    height,
    fontSize,
    iconClassName,
    checkmark,
    border,
    circleMargin,
    checkmarkIconSize,
    checkmarkWidth,
    checkmarkHeight,
    dataTestID,
}) => {
    return (
        <Circle
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            border={border}
            margin={circleMargin}
            data-testid={dataTestID}
        >
            <Icon className={iconClassName} color={color} fontSize={fontSize} />
            {checkmark && (
                <Checkmark width={checkmarkWidth} height={checkmarkHeight}>
                    <CheckmarkIcon
                        className="fas fa-check"
                        fontSize={checkmarkIconSize}
                    ></CheckmarkIcon>
                </Checkmark>
            )}
        </Circle>
    )
}

CircleIcon.defaultProps = {
    color: 'white',
    backgroundColor: 'dkblue1',
    width: 56,
    height: 56,
    fontSize: 20,
    circleMargin: '0 auto',
    checkmarkWidth: 20,
    checkmarkHeight: 20,
    checkmarkIconSize: 10,
}
