//
import React, { Component } from 'react'
import logError from 'utils/errorHandler'

const env = import.meta.env.MODE || 'development'

/**
 * GlobalError Properties
 */

/**
 * GlobalError State
 */

/**
 */
export default class GlobalError extends Component {
    state = {
        hasError: false,
        error: null,
        info: null,
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error, info })
        logError(error, info)
    }

    renderErrorDetails() {
        if (env === 'development') {
            const { error, info } = this.state

            return (
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        color: 'white',
                        background: 'rgba(0,0,0,0.8)',
                        padding: '2em',
                    }}
                >
                    <h4>ERROR DETAILS:</h4>
                    <p>
                        <b>Error:</b>
                        {JSON.stringify(error)}
                    </p>
                    <p>
                        <b>Info:</b>
                        {JSON.stringify(info)}
                    </p>
                    <small style={{ color: 'red' }}>
                        You see this because you have RAILS_ENV set to
                        'development'. Please include this in the bug report.
                    </small>
                </div>
            )
        } else {
            // render nothing in other environments
            return null
        }
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children
        }

        return (
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    background: '#fff',
                }}
            >
                {this.renderErrorDetails.bind(this)}
                <div
                    style={{
                        border: 'solid 1px #f0f0f0',
                        padding: '10px 30px 30px 30px',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translateX(-50%) translateY(-50%)',
                    }}
                >
                    <h2>Error Occurred</h2>
                    <div>
                        Sorry, something went wrong. Please try to reload the
                        page.
                    </div>
                </div>
            </div>
        )
    }
}
